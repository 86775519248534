<div class="card bg-base-200 shadow-xl m-2">
  <div class="card-body">
    <div>
      <img
        src="assets/pricklypear-logo.svg"
        alt="PricklyPear Logo" />
    </div>
      <div class="grid grid-cols-1 gap-5 mt-5">
        @if(!submitted) {
        <div>
          <span class="label-text">Password</span>
          <input type="password" id="password" name="password" [(ngModel)]="password" class="input input-bordered w-full" (ngModelChange)="passwordChanged()"/>
        </div>
        <div>
          <span class="label-text">Confirm Password</span>
          <input type="password" id="confirmPassword" name="confirmPassword" [(ngModel)]="confirmPassword" class="input input-bordered w-full" (ngModelChange)="passwordChanged()"/>
        </div>
        <div>
          <div>
            Password must match and contain at least:
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': isUppercase, 'text-error fa-times': !isUppercase}"></i> One uppercase letter
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': isLowercase, 'text-error fa-times': !isLowercase}"></i> One lowercase letter
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': isNumber, 'text-error fa-times': !isNumber}"></i> One number
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': isSpecial, 'text-error fa-times': !isSpecial}"></i> One special character
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': password.length >= 8, 'text-error fa-times': password.length < 8}"></i> 8 total characters
          </div>
          <div>
            <i class="fas fa-fw" [ngClass]="{'text-success fa-check': isEqual, 'text-error fa-times': !isEqual}"></i> Passwords match
          </div>
        </div>
        <div>
          <button type="button" (click)="submit()" class="btn btn-primary w-full" [disabled]="!isValid()">Change Password</button>
        </div>
        } @else {
          @if(success) {
            <div class="text-3xl text-center text-success">
              <i class="fa-regular fa-circle-check fa-fw fa-2xl"></i>
            </div>
            <div class="text-xl text-center">
              You have successfully changed your password. Please sign in using the Prickly Pear App.
            </div>
          } @else {
            <div class="text-3xl text-center text-error">
              <i class="fa-regular fa-circle-xmark fa-fw fa-2xl"></i>
            </div>
            <div class="text-xl text-center">
              Changing your password failed. Please go back to the Prickly Pear App and request a new reset password email.
            </div>
          }
        }
      </div>
  </div>
</div>

