import { Component } from '@angular/core';
import { SupabaseClient } from "@supabase/supabase-js";
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-account-reset-password',
  templateUrl: './account-reset-password.component.html',
  styleUrl: './account-reset-password.component.scss'
})
export class AccountResetPasswordComponent {
  supabaseClient: SupabaseClient;
  password: string;
  confirmPassword: string;
  lowercase: RegExp;
  uppercase: RegExp;
  number: RegExp;
  special: RegExp;
  isUppercase: boolean;
  isLowercase: boolean;
  isNumber: boolean;
  isSpecial: boolean;
  isEqual: boolean;
  isValidLength: boolean;
  submitted: boolean;
  success: boolean;

  constructor() {
    this.supabaseClient = new SupabaseClient(
      environment.supabaseUrl,
      environment.supabaseKey,
      {
        auth:
          {
            lock: (a, b, fn) => fn()
          }
      }
    );
    this.password = '';
    this.confirmPassword = '';
    this.lowercase = new RegExp('[a-z]');
    this.uppercase = new RegExp('[A-Z]');
    this.number = new RegExp('[0-9]');
    this.special = new RegExp('[~!@#$%^&*()_={}:<>?,.+]');
    this.isUppercase = false;
    this.isLowercase = false;
    this.isNumber = false;
    this.isSpecial = false;
    this.isEqual = false;
    this.isValidLength = false;
    this.submitted = false;
    this.success = false;
  }

  passwordChanged(): void {
    this.isLowercase = this.lowercase.test(this.password);
    this.isUppercase = this.uppercase.test(this.password);
    this.isNumber = this.number.test(this.password);
    this.isSpecial = this.special.test(this.password);
    this.isEqual = this.password === this.confirmPassword && this.password.length > 0 && this.confirmPassword.length > 0;
  }

  isValid(): boolean {
    return this.isLowercase && this.isUppercase && this.isNumber && this.isSpecial && this.isEqual;
  }

  submit() {
    if(this.isValid()) {
      try {
        this.supabaseClient.auth.updateUser({password: this.password});
        this.success = true;
        this.submitted = true;
      } catch(error) {
        this.success = false;
        this.submitted = true;
      }
    }
  }
}
