import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountResetPasswordComponent } from "./account-reset-password/account-reset-password.component";

const routes: Routes = [
  {
    path: "account/reset",
    component: AccountResetPasswordComponent
  },
  {
    path: "*",
    redirectTo: '',
    pathMatch: "full"
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
